/* XS */
@media (max-width:599px) {
  .filter-item{
    width: 100%;
    float: right;
    padding: 5px;
  }
  .MuiToggleButtonGroup-root{
    flex-wrap: wrap;
  }
  .MuiToggleButtonGroup-groupedHorizontal:not(:last-child){
    border: 1px solid rgba(0, 0, 0, 0.12) !important;
  }
  .MuiToggleButtonGroup-groupedHorizontal:not(:first-child){
    border: 1px solid rgba(0, 0, 0, 0.12) !important;
  }
}

/* SM */
@media (max-width: 959px) and (min-width: 600px) {
  .filter-item{
    width: 50%;
    float: right;
    padding: 5px;
  }
}

/* MD */
@media (min-width: 960px) and (max-width: 1279px) {
  .filter-item{
    width: 33.33%;
    float: right;
    padding: 5px;
  }
}

/* LG */
@media (min-width: 1280px) and (max-width: 1919px) {
  .filter-item{
    width: 25%;
    float: right;
    padding: 5px;
  }
}

/* XL */
@media (min-width:1920px) {
  .filter-item{
    width: 20%;
    float: right;
    padding: 5px;
  }
}


