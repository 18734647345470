.svg {
  display: flex;
  flex-direction: column;
  margin-left: 100px;
  width: 220px;
  align-items: center;
}

.plainTulha {
  clip-path: path('M3,116 3,4 79,4 79,116z');
  width: 83px !important;
  height: 113px !important;
  top: 55px !important;
  left: 65px !important;
}
.plainTulhaModal {
  clip-path: path('M15,504 15,12 346,12 346,504z');
  width: 430px !important;
  height: 492px !important;
  top: 0px !important;
  left: 180px !important;
  background-size: contain !important;
  background-position: center !important;
  padding-left: 45px;
}

.plain2Tulha {
  clip-path: path('M3,116 3,4 79,4 79,116z');
  width: 83px !important;
  height: 130px !important;
  top: 55px !important;
  left: 65px !important;
}
.plain2TulhaModal {
  clip-path: path('M15,504 15,12 346,12 346,504z');
  width: 430px !important;
  height: 645px !important;
  top: 0px !important;
  left: 180px !important;
  background-size: contain !important;
  background-position: center !important;
  padding-left: 45px;
}

.plain3Tulha {
  clip-path: path('M3,116 3,4 79,4 79,116z');
  width: 83px !important;
  height: 130px !important;
  top: 55px !important;
  left: 65px !important;
}
.plain3TulhaModal {
  clip-path: path('M15,504 15,12 346,12 346,504z');
  width: 430px !important;
  height: 645px !important;
  top: 0px !important;
  left: 180px !important;
  background-size: contain !important;
  background-position: center !important;
  padding-left: 45px;
}

.VTulha {
  // clip-path: path('M3,116 3,3 79,3 79,116 41,141Z');
  clip-path: polygon(0% 82%, 0% 0%, 100% 0%, 100% 82%, 50% 100%);
  width: 135px !important; //85
  height: 236px !important; //156
  top: 23px !important;
  left: 4px !important;
  // clip-path: polygon(0% 80%, 0% 0%, 100% 0%, 100% 80%, 50% 100%);
  // width: 15% !important; // 270px
  // height: 60% !important; //200px
  // top: 40px !important;
  // left: 67px !important;
  // background-size: contain !important;
  // background-position: center !important;
  // padding-top: 60px !important;
}

.VTulhaModal {
  clip-path: path('M16,506 16,14 347,14 347,506 181,613Z');
  width: 430px !important;
  height: 695px !important;
  top: 0px !important;
  left: 283px !important;
  background-size: contain !important;
  background-position: center !important;
  padding-left: 50px;
}

.V2Tulha {
  clip-path: path('M3,116 3,3 79,3 79,116 41,141Z');
  width: 83px !important;
  height: 161px !important;
  top: 55px !important;
  left: 65px !important;
}
.V2TulhaModal {
  clip-path: path('M16,506 16,14 347,14 347,506 181,613Z');
  width: 430px !important;
  height: 700px !important;
  top: 0px !important;
  left: 180px !important;
  background-size: contain !important;
  background-position: center !important;
  padding-left: 45px;
}

.V3Tulha {
  clip-path: path('M3,116 3,3 79,3 79,116 41,141Z');
  width: 83px !important;
  height: 161px !important;
  top: 55px !important;
  left: 65px !important;
}
.V3TulhaModal {
  clip-path: path('M16,506 16,14 347,14 347,506 181,613Z');
  width: 430px !important;
  height: 700px !important;
  top: 0px !important;
  left: 180px !important;
  background-size: contain !important;
  background-position: center !important;
  padding-left: 45px;
}

.cableHeadersTulha {
  display: flex;
  justify-content: center;
  width: 320px;
  margin-left: 285px !important;
}

.heatmap {
  @media (max-width: 800px) {
    padding-left: 50px;
    padding-top: 230px;
  }
}
