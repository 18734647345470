.carousel__slide{
  padding-bottom: inherit !important;
  height: 100% !important;
}
.carousel__inner-slide {
  position: relative;
  width: calc(100% - 20px);
  height: 100% !important;
}

.carousel__back-button:disabled, .carousel__next-button:disabled {
  color: #828282;
  background-color: transparent;
}

.carousel__back-button, .carousel__next-button, .carousel__back-button:focus, .carousel__next-button:focus {
  border: none;
  background-color: #2F80ED;
  color: #FFF;
  outline: none;
}

