/* LIMPEZA LOGIN */
/* .MuiDrawer-root, .makeStyles-root-1 header{
  display: none !important;
}
.MuiContainer-root {
  padding: 0 !important;
  margin: 0 !important;
  width: auto !important;
  max-width: inherit !important;
}
.content{
  margin: 0;
} */

/* --------------------  */
.MuiContainer-root {
  height: 100%;
}

.loginPage{
  display: flex;
  width: 100%;
  height: calc(100vh - 24px);
  background-color: #FFFFFF;
  overflow-y: scroll;
}

.loginPage .slides{
  width: 50%;
}

.loginPage .slides img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: bottom left;
}

.loginPage .form{
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  z-index: 999;
}

.loginPage .form .form-content{
  display: flex;
  flex-direction: column;
  max-width: 480px;
  width: 100%;
  margin: auto;
}


.loginPage .form .form-content .logoHolder{
  display: block;
  justify-content: space-between;
}

.loginPage .form .form-content .logo{
  /* max-width: 50%; */
  max-width: 75%;
  margin: 0 auto 15px;
  /* float: left; */
}

.loginPage .form .form-content .sublogo{
  /* max-width: 50%; */
  max-height: 65px;
  margin: 0 auto 25px;
}

.loginPage .form .form-content .forgotPassword{
  text-align: right;
  margin-top: 7px;
}

.loginPage .form .form-content .forgotPassword span{
  color: #979797;
  text-decoration: underline;
  cursor: pointer;
}

.loginPage .form .form-content .btnSubmit{
  background: #E6F0FD;
  color: #2F80ED;
  border: none;
  border-radius: none !important;
  box-shadow: none !important;
  padding-left: 35px;
  padding-right: 35px;
}

.to-right{
  text-align: right;
}

/* Smartphones (portrait and landscape) ----------- */
@media only screen and (max-width : 599px) {
  /* Styles */
  .loginPage{
    flex-direction: column;
  }
  .loginPage .slides, .loginPage .form{
    width: 100%;
    height: 100%;
  }
  .loginPage .slides {
    position: absolute;
    height: 100%;
  }
  .loginPage .slides img{
    opacity: 0.1;
  }
  .loginPage .form {
    height: 100%;
  }
}

/* iPads (portrait) ----------- */
@media only screen and (min-width : 600px) and (max-width : 959px) {
  /* Styles */
  .loginPage{
    flex-direction: column;
  }
  .loginPage .slides, .loginPage .form{
    width: 100%;
    height: 100%;
  }
  .loginPage .slides {
    position: absolute;
    height: 100%;
  }
  .loginPage .slides img{
    opacity: 0.1;
  }
  .loginPage .form {
    height: 100%;
  }
}
