body {
  margin: 0;
  font-family: 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* html, body, #root {
  height: 100%;
  margin: 0px;
  padding: 0px;
} */

html,
body,
#root {
  margin: 0;
  padding: 0;
  height: 100%;
}

.fixNavbarHeight {
  position: relative;
  top: 68px;
  width: 100%;
  height: calc(100% - 64px);
}

.svgTest {
  z-index: 9999 !important;
}

.svgSmall {
  padding: 0 !important;
}

.svgBackground {
  background: rgba(255, 255, 255, 0.8) !important;
  padding: 20px 0 !important;
}

.svgClose {
  color: black !important;
}

.leaflet-div-icon {
  background: transparent !important;
  border: none !important;
}

@media print {
  @page {
    margin: 20mm;
    margin-top: 0;
    margin-bottom: 0;
    page-break-after: always;
  }
  body {
    padding-top: 72px;
    padding-bottom: 72px;
  }
  .noPrint {
    display: none;
  }
  /* thead {
    display: table-header-group;
  }
  tfoot {
    display: table-footer-group;
  } */
}

iframe {
  display: none;
}
