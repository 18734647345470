$emergency: #b40000;
$standby: #00836d;
$editing: #00e4be;
$water_on: #005db4;
$water_off: #1b8600;
$off: #c7a200;
$fail: #000000;
$no_comm: #111111;
.on_filter {
  fill: $water_on;
  transition: 0.33s;
  cursor: pointer;
}
.no_filter {
  fill: $emergency;
  transition: 0.33s;
  cursor: pointer;
}
.image_filter {
  filter: grayscale(70%) brightness(120%) contrast(1.4);
  transition: 0.33s;
}
.image_nofilter {
  filter: grayscale(0) brightness(1) contrast(1);
  transition: 0.33s;
}
.zero_indicator_circle {
  fill: $standby;
  stroke-width: 2px;
  stroke: $water_on;
  cursor: col-resize;
}
.zero_indicator_line {
  stroke-width: 2px;
  stroke: $water_on;
}
.pivot_area_on {
  stroke-width: 1px;
  stroke: #3d5059;
  fill: #ffffff50;
  fill-opacity: 0.2;
  animation-name: areaEnter;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  animation-duration: 1s;
  animation-direction: normal;
  animation-delay: 0s;
  animation-fill-mode: both;
}
.pivot_contour {
  stroke-width: 4px;
  fill: #ffffff50;
  fill-opacity: 0.2;
  animation-name: areaEnter;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  animation-duration: 1s;
  animation-direction: normal;
  animation-delay: 0s;
  animation-fill-mode: both;
}
.pivot_contour_small {
  stroke-width: 4px;
  animation-name: areaEnter;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  animation-duration: 1s;
  animation-direction: normal;
  animation-delay: 0s;
  animation-fill-mode: both;
}
.pivot_contour.emergency {
  stroke: $emergency;
}
.pivot_contour.standby {
  stroke: $standby;
}
.pivot_contour.water_on {
  stroke: $water_on;
}
.pivot_contour.water_off {
  stroke: $water_off;
}
.pivot_contour.off {
  stroke: $off;
}
.pivot_contour.fail {
  stroke: $fail;
}
.pivot_area_off {
  stroke-width: 1px;
  stroke: #3d5059;
  fill: #ffffff50;
  fill-opacity: 0.2;
  animation-name: areaEnter;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  animation-duration: 1s;
  animation-direction: normal;
  animation-delay: 0s;
  animation-fill-mode: both;
}
.path_water_100 {
  fill: #00b7ff8c;
  animation-name: areaEnter;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  animation-duration: 1s;
  animation-direction: normal;
  animation-delay: 1s;
  animation-fill-mode: both;
}
.path_water_50 {
  fill: #37c6ff77;
  animation-name: areaEnter;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  animation-duration: 1s;
  animation-direction: normal;
  animation-delay: 1s;
  animation-fill-mode: both;
}
.path_water_25 {
  fill: #7bdaff77;
  animation-name: areaEnter;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  animation-duration: 1s;
  animation-direction: normal;
  animation-delay: 1s;
  animation-fill-mode: both;
}
.path_no_water {
  fill: #ffdc7c9f;
  animation-name: areaEnter;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  animation-duration: 1s;
  animation-direction: normal;
  animation-delay: 1s;
  animation-fill-mode: both;
}
.path_water_done {
  fill: $water_on;
  fill-opacity: 0.75;
  animation-name: areaEnter;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  animation-duration: 1s;
  animation-direction: normal;
  animation-delay: 1s;
  animation-fill-mode: both;
}

.path_no_water_done {
  fill: $water_off;
  fill-opacity: 0.45;
  animation-name: areaEnter;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  animation-duration: 1s;
  animation-direction: normal;
  animation-delay: 1.5s;
  animation-fill-mode: both;
}
.current_position_on {
  stroke: #3d5059;
  stroke-width: 2px;
  stroke-linecap: round;
  // filter: drop-shadow(3px 2px 6px rgb(17, 168, 255));
  animation-name: grow;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  animation-duration: 1s;
  animation-direction: normal;
  animation-delay: 1s;
  animation-fill-mode: both;
}
.current_position_off {
  stroke: #3d5059;
  stroke-width: 2px;
  stroke-linecap: round;
  // filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.432));
  animation-name: grow;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  animation-duration: 1s;
  animation-direction: normal;
  animation-delay: 1s;
  animation-fill-mode: both;
}
.with_water {
  fill: #3d5059;
}
.with_no_water {
  fill: #3d5059;
}
.centerline {
  stroke-width: 2px;
  animation-name: grow;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  animation-duration: 1s;
  animation-direction: normal;
  animation-delay: 1s;
  animation-fill-mode: both;
}
.zero_text {
  font-family: Roboto;
  text-anchor: middle;
  font-size: 16px;
}
.zero_text_map {
  font-family: Roboto;
  text-anchor: middle;
  font-size: 16px;
  fill: #ff0000;
}
.on {
  stroke: white;
}
.off {
  stroke: white;
}
.radial_gradient_center {
  transition: 0.33s;
  .gradient0_on_no_water {
    stop-color: rgb(106, 206, 0);
    stop-opacity: 0.75;
  }
  .gradient1_on_no_water {
    stop-color: rgb(0, 133, 8);
    stop-opacity: 0.75;
  }
  .gradient2_on_no_water {
    stop-color: rgb(0, 0, 0);
    stop-opacity: 0.95;
  }

  .gradient0_on_no_water_hover {
    stop-color: rgb(109, 211, 0);
    stop-opacity: 1;
  }
  .gradient1_on_no_water_hover {
    stop-color: rgb(0, 133, 8);
    stop-opacity: 1;
  }
  .gradient2_on_no_water_hover {
    stop-color: rgb(0, 0, 0);
    stop-opacity: 1;
  }

  .gradient0_on_with_water {
    stop-color: rgb(0, 140, 255);
    stop-opacity: 0.75;
  }
  .gradient1_on_with_water {
    stop-color: rgb(0, 62, 133);
    stop-opacity: 0.75;
  }
  .gradient2_on_with_water {
    stop-color: rgb(0, 0, 0);
    stop-opacity: 0.95;
  }
  .gradient0_on_with_water_hover {
    stop-color: rgb(0, 140, 255);
    stop-opacity: 1;
  }
  .gradient1_on_with_water_hover {
    stop-color: rgb(0, 62, 133);
    stop-opacity: 1;
  }
  .gradient2_on_with_water_hover {
    stop-color: rgb(0, 0, 0);
    stop-opacity: 1;
  }

  .gradient0_on_waiting {
    stop-color: rgb(0, 104, 95);
    stop-opacity: 0.75;
  }
  .gradient1_on_waiting {
    stop-color: rgb(0, 155, 158);
    stop-opacity: 0.75;
  }
  .gradient2_on_waiting {
    stop-color: rgb(0, 0, 0);
    stop-opacity: 0.95;
  }
  .gradient0_on_waiting_hover {
    stop-color: rgb(0, 110, 101);
    stop-opacity: 1;
  }
  .gradient1_on_waiting_hover {
    stop-color: rgb(0, 155, 158);
    stop-opacity: 1;
  }
  .gradient2_on_waiting_hover {
    stop-color: rgb(0, 0, 0);
    stop-opacity: 1;
  }

  .gradient0_failure {
    stop-color: rgb(255, 0, 0);
    stop-opacity: 0.75;
  }
  .gradient1_failure {
    stop-color: rgb(133, 0, 0);
    stop-opacity: 0.75;
  }
  .gradient2_failure {
    stop-color: rgb(0, 0, 0);
    stop-opacity: 0.95;
  }
  .gradient0_failure_hover {
    stop-color: rgb(255, 0, 0);
    stop-opacity: 1;
  }
  .gradient1_failure_hover {
    stop-color: rgb(133, 0, 0);
    stop-opacity: 1;
  }
  .gradient2_failure_hover {
    stop-color: rgb(0, 0, 0);
    stop-opacity: 1;
  }

  .gradient0_no_comm {
    stop-color: rgb(85, 85, 85);
    stop-opacity: 0.75;
  }
  .gradient1_no_comm {
    stop-color: rgb(46, 46, 46);
    stop-opacity: 0.75;
  }
  .gradient2_no_comm {
    stop-color: rgb(0, 0, 0);
    stop-opacity: 0.95;
  }
  .gradient0_no_comm_hover {
    stop-color: rgb(85, 85, 85);
    stop-opacity: 1;
  }
  .gradient1_no_comm_hover {
    stop-color: rgb(46, 46, 46);
    stop-opacity: 1;
  }
  .gradient2_no_comm_hover {
    stop-color: rgb(0, 0, 0);
    stop-opacity: 1;
  }

  .gradient0_off {
    stop-color: rgb(255, 196, 0);
    stop-opacity: 0.75;
  }
  .gradient1_off {
    stop-color: rgb(196, 134, 0);
    stop-opacity: 0.75;
  }
  .gradient2_off {
    stop-color: rgb(0, 0, 0);
    stop-opacity: 0.95;
  }
  .gradient0_off_hover {
    stop-color: rgb(255, 196, 0);
    stop-opacity: 1;
  }
  .gradient1_off_hover {
    stop-color: rgb(196, 134, 0);
    stop-opacity: 1;
  }
  .gradient2_off_hover {
    stop-color: rgb(0, 0, 0);
    stop-opacity: 1;
  }
}
.water_blade_mm_mid {
  filter: drop-shadow(0px 0px 5px #000000cc);
  white-space: pre;
  font-family: Roboto;
  fill: #ffffff;
  font-size: 80px;
  text-anchor: middle;
  font-weight: 900;
}
.water_blade_mid {
  filter: drop-shadow(0px 0px 5px #000000cc);
  white-space: pre;
  font-family: Roboto;
  fill: #ffffff;
  font-size: 120px;
  text-anchor: middle;
  font-weight: 900;
}
.clickable {
  cursor: pointer;
}
.center_button {
  cursor: pointer;
  animation-name: grow;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  animation-duration: 1s;
  animation-direction: normal;
  animation-delay: 0.5s;
  animation-fill-mode: both;
  .state {
    filter: drop-shadow(0px 0px 5px #000000cc);
    white-space: pre;
    font-family: Roboto;
    fill: #ffffff;
    font-size: 18px;
    text-anchor: middle;
    font-weight: 900;
  }
  .water_blade {
    filter: drop-shadow(0px 0px 5px #000000cc);
    white-space: pre;
    font-family: Roboto;
    fill: #ffffff;
    font-size: 40px;
    text-anchor: end;
    font-weight: 900;
  }
  .water_blade_mm {
    filter: drop-shadow(0px 0px 5px #000000cc);
    white-space: pre;
    font-family: Roboto;
    fill: #ffffff;
    font-size: 20px;
    text-anchor: end;
    font-weight: 900;
  }

  .angle {
    filter: drop-shadow(0px 0px 5px #000000cc);
    white-space: pre;
    font-family: Roboto;
    fill: #ffffff;
    font-size: 38px;
    text-anchor: end;
    font-weight: 900;
  }
  .center_info {
    stroke: rgba(255, 255, 255, 0.9);
    fill-opacity: 0.7;
    stroke-width: 0px;
    transition: filter 0.33s;
    filter: drop-shadow(0px 0px 50px rgba(0, 0, 0, 0.432))
      drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.432)) blur(16);
  }
  .center_info.warning {
    transition: 0.33s;
    fill: $emergency;
  }
  .center_info.fail {
    transition: 0.33s;
    fill: $fail;
  }

  .center_info.standby {
    transition: 0.33s;
    fill: $standby;
  }
  .center_info.off_state {
    transition: 0.33s;
    fill: $off;
  }
  .center_info.water_on {
    transition: 0.33s;
    fill: $water_on;
  }
  .center_info.water_off {
    transition: 0.33s;
    fill: $water_off;
  }
  .center_info.no_comm {
    transition: 0.33s;
    fill: $no_comm;
  }
}
.center_button_big {
  cursor: pointer;
  animation-name: grow;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  animation-duration: 1s;
  animation-direction: normal;
  animation-delay: 0.5s;
  animation-fill-mode: both;
  .state {
    filter: drop-shadow(0px 0px 5px #000000cc);
    white-space: pre;
    font-family: Roboto;
    fill: #ffffff;
    font-size: 18px;
    text-anchor: middle;
    font-weight: 900;
  }
  .water_blade {
    filter: drop-shadow(0px 0px 5px #000000cc);
    white-space: pre;
    font-family: Roboto;
    fill: #ffffff;
    font-size: 120px;
    text-anchor: middle;
    font-weight: 900;
  }
  .water_blade_mm {
    filter: drop-shadow(0px 0px 5px #000000cc);
    white-space: pre;
    font-family: Roboto;
    fill: #ffffff;
    font-size: 40px;
    text-anchor: middle;
    font-weight: 900;
  }

  .angle {
    filter: drop-shadow(0px 0px 5px #000000cc);
    white-space: pre;
    font-family: Roboto;
    fill: #ffffff;
    font-size: 38px;
    text-anchor: end;
    font-weight: 900;
  }
  .center_info {
    stroke: rgba(255, 255, 255, 0.9);
    fill-opacity: 0.7;
    stroke-width: 0px;
    transition: filter 0.33s;
    filter: drop-shadow(0px 0px 50px rgba(0, 0, 0, 0.432))
      drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.432)) blur(16);
  }
  .center_info.warning {
    transition: 0.33s;
    fill: $emergency;
  }
  .center_info.standby {
    transition: 0.33s;
    fill: $standby;
  }
  .center_info.off_state {
    transition: 0.33s;
    fill: $off;
  }
  .center_info.water_on {
    transition: 0.33s;
    fill: $water_on;
  }
  .center_info.water_off {
    transition: 0.33s;
    fill: $water_off;
  }
  .center_info.no_comm {
    transition: 0.33s;
    fill: $no_comm;
  }
}
.main_container {
  .center_info_map {
    stroke: rgba(255, 255, 255, 0.9);
    fill-opacity: 0.7;
    stroke-width: 0px;
    transition: 0.33s;
    filter: drop-shadow(0px 0px 50px rgba(0, 0, 0, 0.432))
      drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.432)) blur(16);
  }
  .center_info_map.warning {
    transition: 0.33s;
    fill: $emergency;
  }
  .center_info_map.fail {
    transition: 0.33s;
    fill: $fail;
  }
  .center_info_map.standby {
    transition: 0.33s;
    fill: $standby;
  }
  .center_info_map.off_state {
    transition: 0.33s;
    fill: $off;
  }
  .center_info_map.water_on {
    transition: 0.33s;
    fill: $water_on;
  }
  .center_info_map.water_off {
    transition: 0.33s;
    fill: $water_off;
  }
  .center_info_map.no_comm {
    transition: 0.33s;
    fill: $no_comm;
  }
}
.main_container:active {
  .center_info_map {
    transition: 0.33s;
    stroke: rgb(21, 220, 255);
    stroke-width: 8px;
    filter: drop-shadow(0px 0px 36px rgba(0, 0, 0, 0.432))
      drop-shadow(0px 0px 6px rgba(70, 181, 255, 0.534));
  }
}
.center_button:hover {
  .center_info {
    stroke: rgba(255, 255, 255, 0.9);
    fill-opacity: 0.95;
    stroke-width: 0px;
    transition: 0.33s;
    filter: drop-shadow(0px 0px 50px rgba(0, 0, 0, 0.432))
      drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.432)) blur(20);
  }
  .center_info.warning {
    transition: 0.33s;
    fill: $emergency;
  }
  .center_info.standby {
    transition: 0.33s;
    fill: $standby;
  }
  .center_info.off_state {
    transition: 0.33s;
    fill: $off;
  }
  .center_info.water_on {
    transition: 0.33s;
    fill: $water_on;
  }
  .center_info.water_off {
    transition: 0.33s;
    fill: $water_off;
  }
  .center_info.no_comm {
    transition: 0.33s;
    fill: $no_comm;
  }
}
.center_button:active {
  .center_info {
    stroke: rgb(21, 220, 255);
    stroke-width: 8px;
    filter: drop-shadow(0px 0px 36px rgba(0, 0, 0, 0.432))
      drop-shadow(0px 0px 6px rgba(70, 181, 255, 0.534));
  }
  .center_info.warning {
    transition: 0.33s;
    fill: $emergency;
  }
  .center_info.standby {
    transition: 0.33s;
    fill: $standby;
  }
  .center_info.off_state {
    transition: 0.33s;
    fill: $off;
  }
  .center_info.water_on {
    transition: 0.33s;
    fill: $water_on;
  }
  .center_info.water_off {
    transition: 0.33s;
    fill: $water_off;
  }
  .center_info.no_comm {
    transition: 0.33s;
    fill: $no_comm;
  }
}
.main_container {
}
.control_arrows {
  cursor: pointer;
  fill: rgb(201, 201, 201);
  transition: fill 0.33s;
  animation-name: fadeIn;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  animation-duration: 1s;
  animation-direction: normal;
  animation-fill-mode: both;
}
.control_arrows.active {
  fill: rgb(0, 183, 255);
}
.control_arrows_view {
  fill: rgb(201, 201, 201);
  animation-name: fadeIn;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  animation-duration: 1s;
  animation-direction: normal;
  animation-fill-mode: both;
}
.control_arrows_view.active {
  fill: rgb(0, 183, 255);
}
.control_arrows_map {
  fill: rgb(255, 255, 255);
  stroke: black;
  stroke-width: 1px;
}
.control_arrows_map.active {
  fill: rgb(0, 81, 255);
}
.control_arrows.active:hover {
  fill: rgb(44, 195, 255);
}
.control_arrows:hover {
  fill: rgb(206, 244, 255);
}
.control_arrows:active {
  stroke: rgb(21, 220, 255);
  stroke-width: 4px;
}
.zero_position {
  stroke: rgb(0, 0, 0);
  stroke-width: 2px;
  stroke-linecap: round;
  stroke-dasharray: 0px;
  animation-name: grow;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  animation-duration: 1s;
  animation-direction: normal;
  animation-fill-mode: both;
}
.zero_position_map {
  stroke: rgb(255, 0, 0);
  stroke-width: 2px;
  stroke-linecap: round;
  stroke-dasharray: 0px;
  animation-name: grow;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  animation-duration: 1s;
  animation-direction: normal;
  animation-fill-mode: both;
}
.pivot_editing {
  fill: $editing;
  stroke: $standby;
  stroke-width: 2px;
  fill-opacity: 0.7;
  cursor: move;
}
.cannon_path {
  cursor: pointer;
  animation-name: fadeIn;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  animation-duration: 1s;
  animation-direction: normal;
  animation-fill-mode: both;
  animation-delay: 0.5s;
  .cannon_path_text {
    white-space: pre;
    text-anchor: middle;
    opacity: 0;
    transition: 0.33s;
  }
  .cannon_path_trace {
    transition: 0.33s;
    fill: $water_on;
    fill-opacity: 0.75;
  }
  .cannon_path_lines {
    opacity: 0;
    transition: 0.25s;
  }
}
.cannon_path:hover {
  .cannon_path_text {
    opacity: 1;
    fill: rgb(0, 94, 172);
    font-family: Roboto;
    font-size: 18px;
    font-weight: 800;
    white-space: pre;
    text-anchor: middle;
    transition: 0.33s;
  }
  .cannon_path_trace {
    fill: #30acffc5;
  }
  .cannon_path_lines {
    opacity: 1;
    stroke: rgb(0, 94, 172);
  }
}

.starting_info {
  cursor: pointer;
  animation-name: grow;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  animation-duration: 1s;
  animation-direction: normal;
  animation-delay: 0.5s;
  animation-fill-mode: both;
  .starting_circle {
    fill: rgba(35, 95, 0, 0.85);
    stroke-width: 2px;
    stroke: rgb(42, 114, 0);
    transition: 0.33s;
    animation-name: grow;
    animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
    animation-duration: 1s;
    animation-direction: normal;
    animation-delay: 1s;
    animation-fill-mode: both;
  }
  .starting_text {
    font-family: Roboto;
    fill: #ffffff;
    font-size: 22px;
    font-weight: 600;
    text-anchor: middle;
    text-align: center;
    transition: 0.33s;
    animation-name: fadeIn;
    animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
    animation-duration: 1s;
    animation-direction: normal;
    animation-delay: 1s;
    animation-fill-mode: both;
  }
}
.starting_info:hover {
  .starting_circle {
    fill: rgb(35, 95, 0);
    stroke: rgb(50, 138, 0);
  }
  .starting_text {
    font-family: Roboto;
    fill: #ffffff;
    font-size: 22px;
    font-weight: 600;
    text-anchor: middle;
    text-align: center;
  }
}
.ending_info {
  cursor: pointer;
  animation-name: grow;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  animation-duration: 1s;
  animation-direction: normal;
  animation-delay: 0.5s;
  animation-fill-mode: both;
  .ending_circle {
    fill: rgba(216, 0, 0, 0.85);
    stroke-width: 2px;
    stroke: rgb(216, 0, 0);
    transition: 0.33s;
    animation-name: grow;
    animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
    animation-duration: 1s;
    animation-direction: normal;
    animation-delay: 1s;
    animation-fill-mode: both;
  }
  .ending_text {
    font-family: Roboto;
    fill: #ffffff;
    font-size: 22px;
    font-weight: 600;
    text-anchor: middle;
    text-align: center;
    transition: 0.33s;
    animation-name: fadeIn;
    animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
    animation-duration: 1s;
    animation-direction: normal;
    animation-delay: 1s;
    animation-fill-mode: both;
  }
}
.ending_info:hover {
  .ending_circle {
    fill: rgb(216, 0, 0);
    stroke: rgb(255, 0, 0);
  }
  .ending_text {
    font-family: Roboto;
    fill: #ffffff;
    font-size: 22px;
    font-weight: 600;
    text-anchor: middle;
    text-align: center;
  }
}
.recipe_start_line {
  stroke-width: 2px;
  stroke: rgb(94, 255, 0);
  stroke-linecap: round;
  animation-name: grow;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  animation-duration: 1s;
  animation-direction: normal;
  animation-delay: 0.5s;
  animation-fill-mode: both;
}
.recipe_end_line {
  stroke-width: 2px;
  stroke: rgb(216, 0, 0);
  stroke-linecap: round;
  animation-name: grow;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  animation-duration: 1s;
  animation-direction: normal;
  animation-delay: 0.5s;
  animation-fill-mode: both;
}
.recipe_start_line_small {
  stroke-width: 8px;
  stroke: rgb(94, 255, 0);
  stroke-linecap: round;
}
.recipe_end_line_small {
  stroke-width: 8px;
  stroke: rgb(216, 0, 0);
  stroke-linecap: round;
}

@keyframes grow {
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
}
@keyframes areaEnter {
  from {
    transform: scale(0);
    stroke-opacity: 0;
  }

  to {
    transform: scale(1);
    stroke-opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    fill-opacity: 0;
    stroke-opacity: 0;
  }

  to {
    fill-opacity: 1;
    stroke-opacity: 1;
  }
}
