@-webkit-keyframes PropellerMotor_rJJpYhYvM_Animation {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    transform-box: fill-box;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }
  100% {
    -webkit-transform: rotate(-1080deg);
    transform: rotate(-1080deg);
    transform-box: fill-box;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }
}
@keyframes PropellerMotor_rJJpYhYvM_Animation {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    transform-box: fill-box;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }
  100% {
    -webkit-transform: rotate(-1080deg);
    transform: rotate(-1080deg);
    transform-box: fill-box;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }
}
#S1NZ3tnYwf * {
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: cubic-bezier(0, 0, 1, 1);
  animation-timing-function: cubic-bezier(0, 0, 1, 1);
}
#PropellerMotor_rJJpYhYvM {
  -webkit-animation-name: PropellerMotor_rJJpYhYvM_Animation;
  animation-name: PropellerMotor_rJJpYhYvM_Animation;
}
