.root {
  cursor: pointer;
}

.App {
  text-align: center;
}
.activeRow {
  background-color: #718a88;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.swal2-container {
  z-index: 9999 !important;
}

.content {
  flex-grow: 1;
  margin-bottom: 32px;
  overflow-x: hidden;
  /* margin-left: -240px; */
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    margin: 10% auto;
    display: block;
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loginLeft {
  background-color: #102754;
  height: 100%;
}

.fullHeight {
  height: 100%;
}

.loginRight {
  padding: 15px !important;
}

.holdLogin {
  text-align: center;
}

.holdLogin .MuiFormControl-root {
  width: 80%;
}

.warningLogin {
  padding: 10px;
  border: 1px solid red;
  background: coral;
}

.MuiTypography-displayBlock {
  color: rgba(0, 0, 0, 0.54);
}

.customScroll {
  scroll-behavior: smooth;
}
.customScroll ::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
.customScroll ::-webkit-scrollbar-thumb {
  background: #969696;
  border-radius: 4px;
}
.customScroll ::-webkit-scrollbar-thumb:hover {
  background: #828282;
}
.customScroll ::-webkit-scrollbar-track {
  background: #ffffff;
  border-radius: 4px;
  box-shadow: inset 7px 10px 12px #f0f0f0;
}
.recipeItem {
  cursor: pointer;
  padding: 8px;
  display: block;
}
/* {classes.foot} */
.footer {
  border-top: 2px solid #cecece;
  margin-top: 8px;
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: #e5e5e5dd;
  z-index: 3000;
}
.main-container {
  height: 100%;
}
@media screen and (max-width: 599px) {
  .version {
    border: '0';
    text-align: 'right';
    font-size: small;
    font-weight: '500';
    margin: '5px';
  }
  .developer {
    display: 'flex';
    align-items: 'center';
    align-content: 'center';
    justify-content: 'flex-end';
    text-align: right;
    font-size: small;
    padding: 5px;
  }
  .developerLogo {
    width: 72px;
    margin-left: 4px;
  }
  .main-container {
    padding: 0 6px 62px;
    width: 100%;
  }
  .footer-container {
    width: 100%;
    padding: 0 6px;
  }
  .recipeSelector {
    display: block;
    white-space: nowrap;
    overflow-x: scroll;
    height: 100%;
  }
  .recipeItem {
    display: inline-block;
    width: fit-content;
  }
}
@media screen and (max-width: 959px) and (min-width: 600px) {
  .version {
    border: 0;
    text-align: right;
    font-size: 15px;
    font-weight: 500;
    margin: 5px;
  }
  .developer {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: flex-end;
    text-align: right;
    font-size: small;
    padding: 5px;
  }
  .developerLogo {
    width: 100px;
    margin-left: 5px;
  }
  .main-container {
    padding: 0 12px 42px;
    width: 100%;
    margin: 0 auto;
  }
  .footer-container {
    width: 100%;
    padding: 0 12px;
  }
  .recipeSelector {
    display: block;
    white-space: nowrap;
    overflow-x: scroll;
    height: 100%;
  }
  .recipeItem {
    display: inline-block;
    width: fit-content;
  }
}
@media screen and (min-width: 960px) and (max-width: 1279px) {
  .version {
    border: 0;
    text-align: right;
    font-size: 15px;
    font-weight: 500;
    margin: 5px;
  }
  .developer {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: flex-end;
    text-align: right;
    font-size: small;
    padding: 5px;
  }
  .developerLogo {
    width: 100px;
    margin-left: 5px;
  }
  .main-container {
    padding: 0 24px 42px;
    width: 100%;
    margin: 0 auto;
  }
  .footer-container {
    width: 940px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 24px;
  }
  .recipeSelector {
    display: block;
    overflow-y: scroll;
    height: 60vh;
  }
}
@media screen and (min-width: 1280px) and (max-width: 1919px) {
  .version {
    border: 0;
    text-align: right;
    font-size: 15px;
    font-weight: 500;
    margin: 5px;
  }
  .developer {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: flex-end;
    text-align: right;
    font-size: small;
    padding: 5px;
  }
  .developerLogo {
    width: 100px;
    margin-left: 5px;
  }
  .main-container {
    padding: 0 24px 42px;
    width: 100%;
    /* width: 1260px; */
    margin: 0 auto;
  }
  .footer-container {
    width: 1260px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 24px;
  }
  .recipeSelector {
    display: block;
    overflow-y: scroll;
    height: 60vh;
  }
}
@media screen and (min-width: 1920px) {
  .version {
    border: 0;
    text-align: right;
    font-size: 15px;
    font-weight: 500;
    margin: 5px;
  }
  .developer {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: flex-end;
    text-align: right;
    font-size: small;
    padding: 5px;
  }
  .developerLogo {
    width: 100px;
    margin-left: 5px;
  }
  .main-container {
    padding: 0 24px 42px;
    width: 1900px;
    margin: 0 auto;
  }
  .footer-container {
    width: 1900px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 24px;
  }
  .recipeSelector {
    display: block;
    overflow-y: scroll;
    height: 60vh;
  }
}
