.svg {
  display: flex;
  flex-direction: column;
  margin-left: 100px;
  width: 220px;
  align-items: center;
}

.plainBunker {
  clip-path: polygon(0% 100%, 0% 80%, 50% 40%, 100% 80%, 100% 100%);
  width: 310px !important;
  height: 72% !important;
  top: 0px !important;
  left: 25px !important;
  background-size: contain !important;
  background-position: center !important;
  @media (max-width: 480px) {
    top: 60px !important;
    left: 20px !important;
    width: 220px !important;
    height: 180px !important;
  }
}

.plainBunkerModal {
  clip-path: path('M48,532 48,435 365,249 682,435 682,532z');
  width: 690px !important;
  height: 550px !important;
  top: 0px !important;
  left: -5px !important;
  background-size: contain !important;
  background-position: center !important;
  padding-top: 248px;
  padding-left: 15px;
}

.VBunker {
  //clip-path: path('M17,190 17,155 130,88 243,155 243,190 130,231Z');
  clip-path: polygon(50% 30%, 100% 60%, 100% 80%, 50% 100%, 0% 80%, 0% 60%);
  width: 310px !important;
  height: 255px !important;
  top: 60px !important;
  left: 25px !important;
  @media (max-width: 480px) {
    top: 90px !important;
    left: 28px !important;
    width: 200px !important;
    height: 185px !important;
  }
}

.VBunkerModal {
  clip-path: path('M 48,494 48,397 365,211 682,397 682,494 365,609 Z');
  width: 700px !important;
  height: 610px !important;
  top: 40px !important;
  left: -5px !important;
  padding-top: 200px !important;
  background-size: contain !important;
  background-position: center !important;
  padding-left: 35px;
}

.V2Bunker {
  clip-path: path('M17,190 17,155 130,88 243,155 243,190 130,231Z');
  width: 260px !important;
  height: 260px !important;
  top: 80px !important;
  left: 50px !important;
  @media (max-width: 480px) {
    top: 50px !important;
    left: -5px !important;
  }
}
.V2BunkerModal {
  clip-path: path('M 48,494 48,397 365,211 682,397 682,494 365,609 Z');
  width: 865px !important;
  height: 540px !important;
  top: 40px !important;
  left: 0px !important;
  padding-top: 145px !important;
  background-size: contain !important;
  background-position: center !important;
  padding-left: 45px;
}

.V3Bunker {
  clip-path: path('M17,190 17,155 130,88 243,155 243,190 130,231Z');
  width: 260px !important;
  height: 260px !important;
  top: 80px !important;
  left: 50px !important;
  @media (max-width: 480px) {
    top: 50px !important;
    left: -5px !important;
  }
}
.V3BunkerModal {
  clip-path: path('M 48,494 48,397 365,211 682,397 682,494 365,609 Z');
  width: 865px !important;
  height: 540px !important;
  top: 40px !important;
  left: 0px !important;
  padding-top: 145px !important;
  background-size: contain !important;
  background-position: center !important;
  padding-left: 45px;
}

.V4Bunker {
  clip-path: path('M17,190 17,155 130,88 243,155 243,190 130,231Z');
  width: 260px !important;
  height: 260px !important;
  top: 80px !important;
  left: 50px !important;
  @media (max-width: 480px) {
    top: 50px !important;
    left: -5px !important;
  }
}
.V4BunkerModal {
  clip-path: path('M 48,494 48,397 365,211 682,397 682,494 365,609 Z');
  width: 865px !important;
  height: 540px !important;
  top: 40px !important;
  left: 0px !important;
  padding-top: 145px !important;
  background-size: contain !important;
  background-position: center !important;
  padding-left: 45px;
}

.V5Bunker {
  clip-path: path('M17,190 17,155 130,88 243,155 243,190 130,231Z');
  width: 260px !important;
  height: 260px !important;
  top: 80px !important;
  left: 50px !important;
  @media (max-width: 480px) {
    top: 50px !important;
    left: -5px !important;
  }
}
.V5BunkerModal {
  clip-path: path('M 48,494 48,397 365,211 682,397 682,494 365,609 Z');
  width: 865px !important;
  height: 540px !important;
  top: 40px !important;
  left: 0px !important;
  padding-top: 145px !important;
  background-size: contain !important;
  background-position: center !important;
  padding-left: 45px;
}

.cableHeadersBunker {
  display: flex;
  justify-content: space-between;
  width: 600px;
  margin-left: 90px;
  svg {
    margin-left: -15px;
    margin-right: 25px;
  }
}

.heatmap {
  @media (max-width: 800px) {
    padding-left: 50px;
    padding-top: 230px;
  }
}
