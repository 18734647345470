.svg {
  display: flex;
  flex-direction: column;
  margin-left: 100px;
  width: 220px;
  align-items: center;
}

.plainArm {
  clip-path: polygon(0% 100%, 0% 80%, 50% 40%, 100% 80%, 100% 100%);
  width: 97% !important;
  height: 72% !important;
  top: -10px !important;
  left: 15px !important;
  background-size: contain !important;
  background-position: center !important;
  padding-top: 60px !important;
}

.plainArmModal {
  clip-path: polygon(3% 99%, 3% 76%, 50% 37%, 99% 76%, 99% 99%);
  top: -10px !important;
  left: 15px !important;
  padding-top: 223px !important;
  background-size: contain !important;
  background-position: bottom !important;
  padding-left: 15px;
}

.R1Arm {
  clip-path: polygon(0% 100%, 0% 80%, 50% 40%, 100% 80%, 100% 100%);
  width: 97% !important;
  height: 72% !important;
  top: -10px !important;
  left: 15px !important;
  background-size: contain !important;
  background-position: center !important;
  padding-top: 60px !important;
}

.R1ArmModal {
  clip-path: polygon(3% 99%, 3% 76%, 50% 37%, 99% 76%, 99% 99%);
  top: -10px !important;
  left: 15px !important;
  padding-top: 223px !important;
  background-size: contain !important;
  background-position: center !important;
  padding-left: 15px;
}

.semiVArmModal {
  clip-path: polygon(
    3% 89%,
    3% 65%,
    50% 30%,
    98% 65%,
    98% 89%,
    71% 100%,
    29% 100%
  );
  top: -8px !important;
  left: 15px !important;
  padding-top: 140px !important;
  background-size: contain !important;
  background-position: center !important;
  padding-left: 27px;
}
.semiVArm {
  clip-path: polygon(
    0% 89%,
    0% 65%,
    50% 28%,
    100% 65%,
    100% 89%,
    73% 100%,
    27% 100%
  );
  width: 97% !important;
  height: 72% !important;
  top: 2px !important;
  left: 15px !important;
  background-size: contain !important;
  background-position: center !important;
  padding-top: 60px !important;
}

.SR1ArmModal {
  clip-path: polygon(
    3% 89%,
    3% 65%,
    50% 30%,
    98% 65%,
    98% 89%,
    71% 100%,
    29% 100%
  );
  top: -8px !important;
  left: 15px !important;
  padding-top: 140px !important;
  background-size: contain !important;
  background-position: center !important;
  padding-left: 27px;
}
.SR1Arm {
  clip-path: polygon(
    0% 89%,
    0% 65%,
    50% 28%,
    100% 65%,
    100% 89%,
    73% 100%,
    27% 100%
  );
  width: 97% !important;
  height: 72% !important;
  top: 2px !important;
  left: 15px !important;
  background-size: contain !important;
  background-position: center !important;
  padding-top: 60px !important;
}

.VArmModal {
  clip-path: polygon(3% 77%, 3% 55%, 50% 23%, 98% 55%, 98% 77%, 50.5% 99%);
  top: -10px !important;
  left: 15px !important;
  padding-top: 145px !important;
  background-size: contain !important;
  background-position: center !important;
  padding-left: 27px;
}
.VArm {
  clip-path: polygon(0% 76%, 0% 60%, 50% 20%, 100% 60%, 100% 76%, 50% 100%);
  width: 97% !important;
  height: 72% !important;
  top: 10px !important;
  left: 15px !important;
  background-size: contain !important;
  background-position: center !important;
  padding-top: 60px !important;
}

.WArmModal {
  clip-path: polygon(
    3% 77%,
    3% 55%,
    50% 23%,
    98% 55%,
    98% 77%,
    73% 99%,
    50.5% 76%,
    28% 99%
  );
  top: -10px !important;
  left: 15px !important;
  padding-top: 145px !important;
  background-size: contain !important;
  background-position: center !important;
  padding-left: 27px;
}
.WArm {
  clip-path: polygon(
    0% 77%,
    0% 60%,
    50% 20%,
    100% 60%,
    100% 77%,
    74% 100%,
    50% 76%,
    26% 100%
  );
  width: 97% !important;
  height: 72% !important;
  top: 10px !important;
  left: 15px !important;
  background-size: contain !important;
  background-position: center !important;
  padding-top: 60px !important;
}

.cableHeadersArm {
  display: flex !important;
  justify-content: space-between !important;
  width: 820px;
  margin-left: 90px !important;
  svg {
    margin-left: -15px !important;
    margin-right: 25px !important;
  }
  text {
    left: 20px !important;
  }
}

.heatmap {
  @media (max-width: 800px) {
    padding-top: 0 !important;
    padding-bottom: 30px;
    display: flex;
    width: 380px;
    svg {
      width: 10%;
      rect {
        width: 25px;
      }
      text {
        font-size: 10px !important;
      }
    }
  }
}
