.warnings{
    max-height: 6rem;
    overflow-y: scroll;
    text-align: left;
    width: 100%;
}
.error_alert{
    color:#FF0000;
    font-weight: 900;
}
.error_warning{
    color:#1d1d1d;
    font-weight: 600;
}
