.filter-plant{
  width: 20%;
  float: right;
  padding-right: 10px;
  padding-bottom: 10px;
}


/* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
@media (min-width:320px) {
  .filter-plant{
    width: 100%;
  }
  .MuiToggleButtonGroup-root{
    flex-wrap: wrap;
  }
  .MuiToggleButtonGroup-groupedHorizontal:not(:last-child){
    border: 1px solid rgba(0, 0, 0, 0.12) !important;
  }
  .MuiToggleButtonGroup-groupedHorizontal:not(:first-child){
    border: 1px solid rgba(0, 0, 0, 0.12) !important;
  }
}

/* smartphones, Android phones, landscape iPhone */
@media (min-width:480px) {
  .filter-plant{
    width: 100%;
  }
}

/* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
@media (min-width:600px) {
  .filter-plant{
    /* width: 50%; */
    width: 100%;
  }
}

/* tablet, landscape iPad, lo-res laptops ands desktops */
@media (min-width:801px) {
  .filter-plant{
    width: 50%;
    /* width: 33.33%; */
  }
}

/* big landscape tablets, laptops, and desktops */
@media (min-width:1025px) {
  .filter-plant{
    /* width: 25%; */
    width: 33.33%;
  }
}

/* hi-res laptops and desktops */
@media (min-width:1281px) {
  .filter-plant{
    width: 20%;
  }
}

