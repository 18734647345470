$emergency: #b40000;
$standby: #00836d;
$editing: #00e4be;
$water_on: #005db4;
$water_off: #1b8600;
$off: #c7a200;
$fail: #000000;
$no_comm: #111111;
$recipeEnd: #ff0000;
$recipeStart: #00ff00;
$current: #ffffff;
$background: #000000aa;
$squares: 16px;
$fontsize: 0.7rem;
$tiles: 24px;

.label_container {
  background-color: $background;
}
.label {
  padding: 4px;
  border: 1px solid #333333;
  margin: 4px;
  box-sizing: border-box;
}

.line {
  width: 100%;
  height: 2px;
  margin-top: 4px;
}
@media screen and (max-width: 1279px) {
  .label {
    width: 100%;
    height: $tiles;
    float: left;
  }
  .desktop {
    position: unset;
  }
  .map_container {
    height: calc(100vh - 160px);
    width: 100%;
  }
}
@media screen and (min-width: 1280px) {
  .label {
    width: calc(100% - 8px);
    height: $tiles;
  }
  .mobile {
    display: none;
  }
  .map_container {
    height: calc(100vh - 165px);
    width: 100%;
  }
}
.title {
  box-sizing: border-box;
  padding: 4px;
  text-align: center;
  width: 100%;
  font-size: 16px;
  color: white;
}
.label_type {
  width: $squares;
  height: $squares;
  float: left;
}
.label_name {
  height: $squares;
  float: right;
  text-align: right;
  font-size: $fontsize;
  color: white;
}
.current_position {
  background-color: $current;
}
.recipe_start {
  background-color: $recipeStart;
}
.recipe_end {
  background-color: $recipeEnd;
}
.pivot_on_water {
  background-color: $water_on;
}
.pivot_on_no_water {
  background-color: $water_off;
}
.standby {
  background-color: $standby;
}
.pivot_off {
  background-color: $off;
}
.emergency {
  background-color: $emergency;
}
.fail {
  background-color: $fail;
}
.area_point {
  fill: $water_on;
  transition: 0.33s;
  fill-opacity: 0.65;
  animation-name: fadeIn;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  animation-duration: 1s;
  animation-direction: normal;
  animation-fill-mode: both;
}
.plant_name {
  font-family: Roboto;
  transition: 0.33s;
  fill: #ffffff;
  font-size: 30px;
  font-weight: 600;
  text-anchor: middle;
  text-align: center;
  filter: drop-shadow(0px 0px 5px #000000cc);
  animation-name: fadeIn;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  animation-duration: 1s;
  animation-direction: normal;
  animation-fill-mode: both;
}
.show_plant {
  transition: 0.33s;
  opacity: 1;
}
.hide_plant {
  transition: 0.33s;
  opacity: 0;
}

@keyframes fadeIn {
  from {
    fill-opacity: 0;
    stroke-opacity: 0;
  }

  to {
    fill-opacity: 0.65;
    stroke-opacity: 1;
  }
}
