@media screen and (max-width: 959px) {
    .modal_title{
        background-color: #3D5059;
        color: white!important;
        padding-left: 16px!important;
        padding-right: 56px!important;
        .MuiIconButton-root{
            color: white!important;
        }
    }
}
