.svg {
  display: flex;
  flex-direction: column;
  margin-left: 100px;
  width: 220px;
  align-items: center;
}
.plainSilo {
  clip-path: polygon(50% 0%, 100% 23%, 100% 100%, 0% 100%, 0% 23%);
  width: 250px !important;
  height: 280px !important;
  top: 10px !important;
  left: 25px !important;
}

.plainSiloModal {
  clip-path: path(
    'M 58.752 680.832 c 0 -525.312 0 -525.312 0 -525.312 l 286.848 -141.696 l 286.848 141.696 l 0 525.312 l -573.696 0 z'
  );
  width: 640px !important;
  height: 670px !important;
  top: 0px !important;
  left: 65px !important;
  background-size: contain !important;
  background-position: center !important;
  padding-left: 50px;
  display: flex;
  justify-content: center;
}
.semiVSiloModal {
  clip-path: path(
    'M 54.4 630.4 L 54.4 144 L 320 12.8 L 588.8 144 L 588.8 630.4 L 454.4 697.6 L 188.8 697.6 Z'
  );
  width: 690px !important;
  height: 695px !important;
  top: 0px !important;
  left: 88px !important;
  padding-left: 42px;
  // display: flex;
  // text-align: center;
}
.semiVSilo {
  //clip-path: path('M17,197 17,45 100,4 184,45 184,197 142,218 59,218 Z');
  clip-path: polygon(
    50% 0%,
    98% 20%,
    98% 90%,
    75% 100%,
    25% 100%,
    0% 90%,
    0% 20%
  );
  width: 238px !important;
  height: 295px !important;
  top: 7px !important;
  left: 28px !important;
}

.VSiloModal {
  clip-path: path(
    'M 52.7 613.8 L 52.7 139.5 L 310 12.4 L 567.3 139.5 L 567.3 613.8 L 310 737.8 Z'
  );
  width: 636px !important;
  height: 735px !important;
  top: 0px !important;
  left: 100px !important;
  // padding-top: 350px !important;
  padding-left: 42px;
}
.VSilo {
  clip-path: polygon(50% 0%, 98% 17%, 98% 82%, 50% 100%, 0% 82%, 0% 17%);
  width: 235px !important;
  height: 310px !important;
  top: 15px !important;
  left: 33px !important;
}

.elevatedSiloModal {
  clip-path: path(
    'M 52.7 613.8 L 52.7 139.5 L 310 12.4 L 567.3 139.5 L 567.3 613.8 L 310 737.8 Z'
  );
  width: 575px !important;
  height: 753px !important;
  top: 0px !important;
  left: 100px !important;
  padding-left: 42px;
}
.elevatedSilo {
  //clip-path: path('M17,197 17,45 100,4 183,45 183,197 100,238Z');
  clip-path: polygon(50% 0%, 98% 17%, 98% 82%, 50% 100%, 0% 82%, 0% 17%);
  width: 230px !important;
  height: 320px !important;
  top: 10px !important;
  left: 37px !important;
}

.WSiloModal {
  clip-path: path(
    'M 52.7 620 L 52.7 139.5 L 310 12.4 L 567.3 139.5 L 567.3 620 L 437.1 737.8 L 310 607.6 L 179.8 737.8 Z'
  );
  width: 675px !important;
  height: 760px !important;
  top: 0px !important;
  left: 100px !important;
  padding-left: 42px;
}

.WSilo {
  // clip-path: path('M17,200 17,45 100,4 183,45 183,200 141,238 100,196 58,238Z');
  clip-path: polygon(
    49% 0%,
    98% 17%,
    98% 83%,
    74% 100%,
    49% 81%,
    24% 100%,
    0% 83%,
    0% 17%
  );
  width: 235px !important;
  height: 320px !important;
  top: 10px !important;
  left: 35px !important;
}

.cableHeadersSilo {
  display: flex;
  justify-content: center;
  // width: 600px;
  // margin-left: 70px;
}
.heatmap {
  @media (max-width: 800px) {
    overflow-y: scroll !important;
    padding: 0 !important;
    display: flex;
    width: 380px;
  }
}
